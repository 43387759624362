import React, { useEffect, useState } from 'react';
import Layout from '../components/Layout';
import heroimg from '../img/Hero-WHY.png';
import styled, { css } from 'styled-components';
import { navigate } from '@reach/router';

const Styled404 = styled.div`
  text-align: center;
  div {
    position: absolute;
    top: 10%;
    left: 0;
    right: 0;
    margin: auto;
    padding: 0 25px;
    @media (max-width: 900px) {
      top: 20%;
    }
  }
  h1 {
    font-size: 4rem;
    line-height: 4rem;
    font-weight: 400;
  }
  p {
    font-family: 'Oswald', serif;
    font-size: 2rem;
    font-weight: 300;
  }
`;

const StyledRedirect = styled.div`
  .content {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const NotFoundPage = (data) => {
  const [isRedirect, setIsRedirect] = useState(true);
  useEffect(() => {
    if (window.location.pathname.startsWith('/agency-careers/')) {
      navigate('/agency-careers/', { replace: true });
    } else {
      setIsRedirect(false);
    }
  }, [isRedirect]);

  return (
    <>
      {!isRedirect ? (
        <Layout>
          <Styled404>
            <img src={heroimg} />
            <div>
              <h1>D'OH!</h1>
              <p>We couldn't find that. But, don't fret. Just click another enticing option. We're with you.</p>
            </div>
          </Styled404>
        </Layout>
      ) : (
        <Layout useClass="blank-404-layout">
          <StyledRedirect>
            <div className="content">
              <p>Redirecting...</p>
            </div>
          </StyledRedirect>
        </Layout>
      )}
    </>
  );
};

export default NotFoundPage;
